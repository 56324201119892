/**
 * @imarcsgroup/client:src/entrypoints/start.js
 */

/**
 * Setup API Start.
 */
(async function apiSetupStart() {
  const { $, $$ } = global;
  if(!(
    $$ && typeof $$ === 'object' &&                         // Must have global $$ object.
        /^\d+\.\d+\.\d+$/.test($$.VERSION) &&               // Must have a version attached.
        $$.setup && typeof $$.setup === 'object' &&         // Must have the setup object.
        $$.log && typeof $$.log.info === 'function' &&      // Must have a logger attached.
        $$.setup.library === true                           // The api-client-library should have been setup.
  )) {
    throw new Error('api-client-start requires api-client-library.');
  }
  if(!$) {
    throw new Error('api-client-start requires jquery.');
  }
  const body = $('body');
  if(!body.length) {
    throw new Error('api-client-start must inside of body tag.');
  }
  if($$.setup.start) {
    throw new Error('api-client-start must only be included once.');
  } else if($$.setup.end) {
    throw new Error('api-client-start must be included before api-client-end.');
  }
  $$.setup.start = true;
  global.dispatchEvent(new Event('api-setup-start'));
})();
